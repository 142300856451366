import React, { FC, useEffect, useRef } from 'react';
import imageSDK from '@wix/image-client-api/dist/imageClientSDK';
import {
  ImageHoverEffectOptions as HoverEffectOptions,
  ImageLoadingBehaviorOptions as LoadingBehaviorOptions,
  ImageResizeOptions as ResizeOptions,
  ThumbnailImage,
} from 'wix-ui-tpa';
import { ImageDto } from '@wix/bookings-uou-types';
import { ImageResizeOptions } from '../../../../../../types/types';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { classes, st } from './ServiceImage.st.css';
import { DataHooks } from './consts';

export type ServiceImageProps = {
  image: ImageDto | null;
  imageTitle: string;
  width: number;
  height: number;
  setBoundingClientRect?: Function;
  onClick: () => void;
};

const mapImageResizeOptionsToResizeOptions = {
  [ImageResizeOptions.CROP]: ResizeOptions.cover,
  [ImageResizeOptions.FIT]: ResizeOptions.contain,
};

export const ServiceImage: FC<ServiceImageProps> = (props) => {
  const { image, imageTitle, height, setBoundingClientRect, onClick } = props;
  const { experiments } = useExperiments();
  const { isSEO, serviceImageViewModel, seo } = useWidgetViewModel();
  const isFixImageSizeDownloadingEnabled = experiments.enabled(
    'specs.bookings.FixImageSizeDownloading',
  );
  const isSEOFlow = isFixImageSizeDownloadingEnabled ? isSEO : seo;
  const { imageResize, aspectRatio, focalPoint, isRoundGrid } =
    serviceImageViewModel;
  const imageRef = useRef() as React.MutableRefObject<any>;
  const { t } = useTranslation();
  const width = isRoundGrid ? height : props.width;

  useEffect(() => {
    setTimeout(() => {
      if (imageRef?.current?.getBoundingClientRect()) {
        setBoundingClientRect &&
          setBoundingClientRect(imageRef.current.getBoundingClientRect());
      }
    }, 100);
  }, [imageRef, width, height]);

  const getImageUrl = () => {
    const imageDimensions = isSEOFlow
      ? {
          width: isRoundGrid ? image?.height : image?.width,
          height: image?.height,
        }
      : { width, height };

    if (image) {
      return imageResize === ImageResizeOptions.CROP
        ? imageSDK.getScaleToFillImageURL(
            image.relativeUri,
            image.width,
            image.height,
            imageDimensions.width,
            imageDimensions.height,
            { focalPoint },
          )
        : imageSDK.getScaleToFitImageURL(
            image.relativeUri,
            image.width,
            image.height,
            imageDimensions.width,
            imageDimensions.height,
          );
    }
    return null;
  };

  return (
    <div
      data-hook={DataHooks.ROOT}
      className={st(classes.root, {
        shape: isRoundGrid ? 'round' : 'square',
      })}
      ref={setBoundingClientRect ? imageRef : undefined}
    >
      <div
        data-hook={DataHooks.WRAPPER}
        className={classes.wrapper}
        style={{
          height: isRoundGrid ? `calc(100% - 32px)` : undefined,
          width: isRoundGrid ? `calc(${100 / aspectRatio}% - 32px)` : undefined,
        }}
      >
        <div
          data-hook={DataHooks.CONTAINER}
          onClick={onClick}
          className={classes.clickable}
          role="presentation"
          tabIndex={-1}
        >
          {image ? (
            <ThumbnailImage
              key={`image-key-${height}-${width}-${imageResize}-${focalPoint.x}-${focalPoint.y}-${aspectRatio}`}
              alt={t(imageTitle)}
              fluid
              width={isSEOFlow ? image.width : width}
              height={isSEOFlow ? image.height : height}
              focalPoint={focalPoint}
              loadingBehavior={LoadingBehaviorOptions.blur}
              aspectRatio={aspectRatio}
              hoverEffect={HoverEffectOptions.darken}
              resize={mapImageResizeOptionsToResizeOptions[imageResize]}
              data-hook={DataHooks.IMAGE}
              className={classes.image}
              src={getImageUrl()}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
